<template>
    <div>

        <b-tabs content-class="pt-1" fill>
            <b-tab title="Fees">
                <b-card>
                    <b-row>
                        <b-col md="6">
                            <button @click="sendMailPdfDetailBooking" style="background:#ff7100 !important"
                                class="btn btn-primary mt-1">Mail booking details to
                                Customer</button>
                        </b-col>
                        <b-col offset-md="2" md="4">
                            <template v-if="booking.status_code">
                                <span style="font-weight: bold;">Status trip</span>
                                <v-select @close="changeStatusBooking" v-model="selectedStatusCode" label="title"
                                    :options=optionsStatusCodes class="select-size-sm" />
                            </template>
                        </b-col>
                    </b-row>

                    <gmap-autocomplete style="visibility: hidden;"></gmap-autocomplete>
                    <b-row>
                        <!-- is_automatic_fee -->
                        <b-col md="12">
                            <b-form-checkbox @change="calculatePrice" class="mt-2 mb-2" value="1"
                                v-model="is_automatic_fee">
                                Default values fees
                            </b-form-checkbox>

                            <div style="text-align: right;" class="mb-2">
                                <router-link style="text-align: right;" :to="{ name: 'prices-help' }" target="_blank">
                                    Information about prices
                                </router-link>
                            </div>

                        </b-col>
                        <b-col md="3" v-if="isSelfpay == false">
                            <b-form-group label="Each Way Fee">
                                <b-form-input :disabled="is_automatic_fee == 1" v-model="booking.each_way_fee" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Mile Fee">
                                <b-form-input :disabled="is_automatic_fee == 1" v-model="booking.mile_fee" />
                            </b-form-group>
                        </b-col>
                        <!-- Waiting Time Fee -->
                        <b-col md="3">
                            <b-form-group label="Waiting Time Fee">
                                <validation-provider #default="{ errors }" rules="required" name="Waiting Time Fee">
                                    <b-form-input :disabled="is_automatic_fee == 1"
                                        :state="errors.length > 0 ? false : null" v-model="booking.wait_time_fee" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Additional Stop Fee -->
                        <b-col md="3">
                            <b-form-group label="Additional Stop Fee">
                                <validation-provider #default="{ errors }" rules="required" name="Additional Stop Fee">
                                    <b-form-input :disabled="is_automatic_fee == 1"
                                        :state="errors.length > 0 ? false : null"
                                        v-model="booking.additional_stop_fee" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Cancellation Fee -->
                        <b-col md="3" v-if="isSelfpay == false">
                            <b-form-group label="Cancellation Fee">
                                <validation-provider #default="{ errors }" rules="required" name="Cancellation Fee">
                                    <b-form-input :disabled="is_automatic_fee == 1"
                                        :state="errors.length > 0 ? false : null" v-model="booking.cancellation_fee" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Minimum Fee -->
                        <b-col md="3" v-if="isSelfpay == false">
                            <b-form-group label="Minimum Fee">
                                <validation-provider #default="{ errors }" rules="required|integer" name="Minimum Fee">
                                    <b-form-input :disabled="is_automatic_fee == 1"
                                        :state="errors.length > 0 ? false : null" v-model="booking.minimum_fee" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Minimum Miles Fee -->
                        <b-col md="3" v-if="isSelfpay == false">
                            <b-form-group label="Minimum Miles Fee">
                                <validation-provider #default="{ errors }" rules="required" name="Minimum Miles Fee">
                                    <b-form-input :disabled="is_automatic_fee == 1"
                                        :state="errors.length > 0 ? false : null" v-model="booking.minimum_miles_fee" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Additional Miles Fee -->
                        <b-col md="3" v-if="isSelfpay == true">
                            <b-form-group label="Additional Miles Fee">
                                <validation-provider #default="{ errors }" rules="required" name="Additional Miles Fee">
                                    <b-form-input :disabled="is_automatic_fee == 1"
                                        :state="errors.length > 0 ? false : null"
                                        v-model="booking.additional_miles_fee" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="4">
                            <b-form-checkbox @change="calculateDistance" class="mt-2 mb-2" value="1"
                                v-model="is_automatic_miles">
                                Distance automatically
                            </b-form-checkbox>
                        </b-col>

                    </b-row>

                    <b-row>
                        <!-- Total miles -->
                        <b-col md="4">
                            <b-form-group label="Total distance(miles)">
                                <validation-provider #default="{ errors }" rules="required" name="Total miles Name">
                                    <b-form-input :disabled="is_automatic_miles == 1"
                                        :state="errors.length > 0 ? false : null" v-model="booking.total_miles" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Total waiting time -->
                        <b-col md="4">
                            <b-form-group label="Total waiting time">
                                <validation-provider #default="{ errors }" rules="required"
                                    name="Total waiting time Name">
                                    <b-form-timepicker local="de" locale="de" v-model="booking.total_waiting_time"
                                        :disabled="is_automatic_waiting_time == 1"
                                        :state="errors.length > 0 ? false : null" />
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Total Additional Stops -->
                        <b-col md="4">
                            <b-form-group label="Total Additional Stops">
                                <validation-provider #default="{ errors }" rules="required|integer"
                                    name="Total Additional Stops">
                                    <b-form-input :disabled="is_automatic_additional_stops == 1"
                                        :state="errors.length > 0 ? false : null"
                                        v-model="booking.total_additional_stops" />
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- Service Type -->
                        <b-col md="4" v-if="isSelfpay == false">
                            <b-form-group label="Service type">
                                <validation-provider #default="{ errors }" rules="required" name="Service Type">
                                    <v-select v-model="selectedServiceType" :options="optionsServiceType" label="title"
                                        placeholder="Service Type" />
                                    <small class="text-danger" v-if="errors[0]">{{
                                        errors[0]
                                        }}</small>
                                </validation-provider>
                            </b-form-group>

                        </b-col>
                        <!-- Trip Type -->
                        <b-col md="4">
                            <b-form-group label="Trip Type">
                                <validation-provider #default="{ errors }" rules="required" name="Trip Type">
                                    <v-select v-model="selectedTripType"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="optionsTripType"
                                        label="title" placeholder="Please select some item"
                                        :state="errors.length > 0 ? false : null" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="No show/Cancelation fee for client">
                                <validation-provider #default="{ errors }" rules="" name="Total Price">
                                    <b-form-input :state="errors.length > 0 ? false : null"
                                        v-model="booking.no_show_cancelation_fee_client" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <!-- is_automatic_price -->
                        <b-col md="12">
                            <b-form-group label="">
                                <div style="display:flex">
                                    <b-form-radio @change="calculatePrice" v-model="is_automatic_price"
                                        name="radios-automatic-price" value="1">Calculate price
                                        automatically</b-form-radio>
                                    <b-form-radio  class="ml-2" v-model="is_automatic_price"
                                        name="radios-automatic-price" value="0">Fixed
                                        price</b-form-radio>
                                </div>
                            </b-form-group>
                        </b-col>
                        <!-- Total Price -->
                        <b-col md="4">
                            <b-form-group label="Total Price(not including extra charge)">
                                <validation-provider #default="{ errors }" rules="required" name="Total Price">
                                    <b-form-input :disabled="is_automatic_price == 1"
                                        :state="errors.length > 0 ? false : null" v-model="booking.price" />
                                    <small class="text-danger" v-if="errors[0]">This field is required</small>
                                </validation-provider>
                            </b-form-group>
                            <div v-if="booking.tripSupported == 'no' && is_automatic_fee === '1' && is_automatic_price === '1'"
                                style="color:red">
                                ***Trip is not supported
                            </div>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Total Price(including extra charge)">
                                <b-form-input disabled v-model="totalWithExtraCharge" style="background: cornsilk;" />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <template v-if="getChargeId(booking) != null">
                                <a :href="'https://dashboard.stripe.com/payments/' + getChargeId(booking)"
                                    target="_blank">
                                    View Stripe
                                    Payment</a>
                                <h5>ChargeId: {{ getChargeId(booking) }}</h5>
                            </template>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col md="12 save-btn" style="text-align:right;">
                            <button @click="saveData" class="btn btn-primary">Save</button>
                        </b-col>
                    </b-row>
                    <!-- </form>
            </ValidationObserver> -->
                </b-card>
            </b-tab>


            <b-tab title="Totals">
                <b-card>



                    <div style="float: right;">
                        <table style="width: 700px;">

                            <tr>
                                <td>
                                    Type pdf
                                    <hr>
                                </td>
                                <td>
                                    <b-form-radio class="mb-0" v-model="booking.is_breakdown_pdf"
                                        name="radios-is_breakdown_pdf" value="1">Breakdown pdf</b-form-radio>
                                    <hr>
                                </td>
                                <td>
                                    <b-form-radio class="ml-2 mb-0" v-model="booking.is_breakdown_pdf"
                                        name="radios-is_breakdown_pdf" value="0">No breakdown pdf</b-form-radio>
                                    <hr>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Type price
                                    <hr>
                                </td>
                                <td>
                                    <b-form-radio class="mb-0" @change="calculatePrice" v-model="is_automatic_price"
                                        name="radios-automatic-prices" value="1">Calculate price
                                        automatically</b-form-radio>
                                    <hr>
                                </td>
                                <td>
                                    <b-form-radio class="ml-2 mb-0" v-model="is_automatic_price"
                                        @change="getChargesBooking(booking.id)" name="radios-automatic-prices"
                                        value="0">Fixed
                                        price</b-form-radio>
                                    <hr>
                                </td>
                            </tr>

                            <tr v-if="isSelfpay == false">
                                <td>
                                    Base charges
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.base_charge"
                                        :disabled="is_automatic_price == 1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <template v-if="isSelfpay == true">
                                        Base charges
                                    </template>
                                    <template v-if="isSelfpay == false">
                                        Miles charges
                                    </template>                                   
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.miles_charges"
                                        :disabled="is_automatic_price == 1" />
                                </td>
                            </tr>
                            <tr v-if="isSelfpay == true">
                                <td>
                                    Additional miles charges
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.additional_miles_charges"
                                        :disabled="is_automatic_price == 1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Additional Stop Charges
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.additional_stops_charges"
                                        :disabled="is_automatic_price == 1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Wait time charges
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.wait_time_charges"
                                        :disabled="is_automatic_price == 1" />
                                </td>
                            </tr>
                            <tr v-if="booking.hasMinimumFee == true && is_automatic_price == '1'">
                                <td>
                                    Minimum Fee
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.minimum_charge" :disabled="true" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Wait Time Exceed
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.waiting_exceeded_amount" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Parking charges
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.parking_fees" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Wheelchair and other equipment rental
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.wheelchair_other_equipment_rental" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bariatric
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.bariatric_charge" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other charges
                                </td>
                                <td>
                                    <b-form-input type="number" v-model="booking.others_additional_fees" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <br>
                                    <Span style="font-weight: bolder;">Total price(not including extra charge)</Span>
                                </td>
                                <td>
                                    <b-form-input type="number" class="mt-2" v-model="booking.price"
                                        :disabled="is_automatic_price == 1" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <br>
                                    <Span style="font-weight: bolder;">Total price(including extra charge)</Span>
                                </td>
                                <td>
                                    <b-form-input type="number" class="mt-2" v-model="totalWithExtraCharge" disabled
                                        style="background: cornsilk;" />
                                </td>
                            </tr>

                            <tr>
                                <td>

                                </td>
                                <td style="float:right">
                                    <button @click="saveChargesBooking" class="btn btn-primary mt-2">Save</button>
                                </td>
                            </tr>
                        </table>
                    </div>



                </b-card>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { getMessageError } from "@core/utils/utils";
import { doCalculations } from '@core/utils/distances';
import { toFixed, convertToNumber } from "@core/utils/numbers_utils";
import { getMenuOptionsTripType, getMenuOptionsServiceType } from "@core/utils/menus";
import {GetTotalExtraCharge,} from "@core/utils/utils";

import {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BForm,
    BFormCheckbox,
    BCard,
    BTabs,
    BTab,
} from "bootstrap-vue";

export default {
    components: {
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormSelect,
        BFormDatepicker,
        BFormTimepicker,
        vSelect,
        BTableSimple,
        BThead,
        BTr,
        BTd,
        BTh,
        BTbody,
        BTfoot,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormCheckbox,
        BCard,
        BTabs,
        BTab,
    },
    props: {
        bookingId: 0,
    },
    data() {
        return {
            booking: {
                total_miles: null,
                total_waiting_time: null,
                total_additional_stops: null,
                service_type: null,
                trip_type: 99999,
                price: null,
                each_way_fee: null,
                mile_fee: null,
                wait_time_fee: null,
                additional_stop_fee: null,
                cancellation_fee: null,
                minimum_fee: null,
                minimum_miles_fee: null,
                additional_miles_fee: null,
                is_automatic_fee: 0,
                is_automatic_miles: 0,
                is_automatic_price: null,
                is_automatic_waiting_time: null,
                is_automatic_additional_stops: null,
                each_way_fee: null,
                mile_fee: null,
                is_reserve_by_ca: false,
                facility_phone_number: 78,
                tripSupported: 132,
            },
            totalWithExtraCharge: 0,
            //SELETED OPTIONS
            selectedServiceType: {
                title: "",
                value: 0
            },
            selectedTripType: null,
            selectedStatusCode: {
                title: "",
                value: 0
            },

            //OPTIONS MENUS
            optionsServiceType: [],
            optionsTripType: [],
            optionsStatusCodes: [],

            //OTHERS
            isSelfpay: false,
            calculaInputNormal: false,
            is_automatic_fee: null,
            is_automatic_miles: null,
            is_automatic_price: null,
            is_automatic_waiting_time: null,
            is_automatic_additional_stops: null,
            oldStatusBooking: null,

            hasMinimumFee: false,
            is_breakdown_pdf: null,
        }
    },
    methods: {
        //get the info of the booking
        async getInformationBooking(idBooking) {
            this.$swal({
                title: "Please, wait geting info...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/panel/booking/${idBooking}/info`)
                .then((response) => {
                    this.booking = response.data.data;
                    this.booking.total_miles = toFixed(this.booking.total_miles, 0);
                    this.booking.tripSupported = 'yes';
                    this.is_automatic_fee = this.booking.is_automatic_fee === 1 ? "1" : "0";
                    this.is_automatic_miles = this.booking.is_automatic_miles === 1 ? "1" : "0";
                    this.is_automatic_price = this.booking.is_automatic_price === 1 ? "1" : "0";
                    this.is_automatic_waiting_time = this.booking.is_automatic_waiting_time === 1 ? "1" : "0";
                    this.is_automatic_additional_stops = this.booking.is_automatic_additional_stops === 1 ? "1" : "0";
                    this.isSelfpay = response.data.data.self_pay.ca_id > 0 ? false : true;
                    this.booking.is_breakdown_pdf = this.booking.is_breakdown_pdf === 1 ? "1" : "0";
                    this.oldStatusBooking = structuredClone(this.booking.status);
                    this.setOptionMenus(this.booking);
                    this.calculatePrice(false);
                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error ",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async getChargesBooking(idBooking) {

            this.$swal({
                title: "Please, wait geting info...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/panel/booking/${idBooking}/info`)
                .then((response) => {
                    this.booking = response.data.data;
                    this.booking.base_charge = toFixed(response.data.data.base_charge, 0);
                    this.booking.additional_miles_charges = toFixed(response.data.data.additional_miles_charges, 0);
                    this.booking.miles_charges = toFixed(response.data.data.miles_charges, 0);
                    this.booking.wait_time_charges = toFixed(response.data.data.wait_time_charges, 0);
                    this.booking.additional_stops_charges = toFixed(response.data.data.additional_stops_charges, 0);
                    this.booking.minimum_charge = toFixed(response.data.data.minimum_charge, 0);

                    this.$swal.close();
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error ",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async loadMenus() {
            this.optionsTripType = getMenuOptionsTripType();
            this.optionsServiceType = getMenuOptionsServiceType();
            await this.getStatusCodes();

            this.selectedTripType = this.optionsTripType[0];
            this.selectedServiceType = this.optionsServiceType[0];
        },
        setOptionMenus(booking) {
            this.selectedTripType = this.optionsTripType.find(x => x.value == booking.trip_type);
            this.selectedServiceType = this.optionsServiceType.find(x => x.value == booking.service_type);
            this.selectedStatusCode = this.optionsStatusCodes.find(x => x.value == booking.status);
        },
        setValuesFees() {
            this.booking.is_automatic_fee = this.is_automatic_fee === "1" ? 1 : 0;
            this.booking.is_automatic_miles = this.is_automatic_miles === "1" ? 1 : 0;
            this.booking.is_automatic_price = this.is_automatic_price === "1" ? 1 : 0;
            this.booking.is_automatic_waiting_time = this.is_automatic_waiting_time === "1" ? 1 : 0;
            this.booking.is_automatic_additional_stops = this.is_automatic_additional_stops === "1" ? 1 : 0;
        },
        validationForm() {
            let message = "";

            if (this.booking.status == null) {
                this.$swal({
                    title: "Status trip is required",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
                return false
            }

            if (this.booking.status == 11 && this.booking.no_show_cancelation_fee_client == null) {
                message = "No show cancellation fee client must have a value";
            }
            if (this.booking.status == 11 && this.booking.no_show_cancelation_fee_driver == null) {
                message = "No show cancellation fee driver must have a value";
            }
            if (this.booking.status == 13 && this.booking.no_show_cancelation_fee_client == null) {
                message = "No show cancellation fee client must have a value";
            }

            if (message.length > 0) {
                this.$swal({
                    title: message,
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
                return false;
            }

            // if (this.booking.price < 1) {
            //     this.$swal({
            //         title: "Price must not be less than 1",
            //         icon: "error",
            //         customClass: {
            //             confirmButton: "btn btn-primary",
            //         },
            //         buttonsStyling: false,
            //     });
            //     return false
            // }
        },
        async updateFeesBooking() {
            this.$swal({
                title: "Please, wait updating fees...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            this.setValuesFees();

            await this.$http
                .post(`admin/panel/booking/update_fees_booking`, this.booking)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$swal.close();
                        this.inhabilitar = true;
                    } else {
                        let message = getMessageError(res);
                        this.$swal({
                            title: message,
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                    }
                })
                .catch((res) => {
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        getPriceNormalInput(type, calculate) {
            if (type == 'in') {
                this.calculaInputNormal = calculate;
            }
            if (type == 'out' && this.calculaInputNormal === true) {
                this.calculatePrice();
                this.calculaInputNormal = calculate;
            }
        },
        async calculatePrice(updateBookingPrice = true) {
            this.$swal({
                title: "Please, wait calculating price...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let formData = new FormData();
            let corporate_account_id = 0;
            let servicetype = null;
            let miles = this.booking.total_miles;
            let waitingTime = 0;
            let trip_type = 0;
            let additionalStop = 0;
            let url = `admin/panel/calculate_price_selfpay_nup`;

            //get waiting time from additional services
            try {
                waitingTime = this.booking.total_waiting_time;
            } catch (err) {
            }

            //get additional stops from additional services
            try {
                if (this.booking.total_additional_stops) {
                    additionalStop = this.booking.total_additional_stops
                }
            } catch (err) {
                console.log(err)
            }

            trip_type = this.selectedTripType === undefined ? this.optionsTripType[0].value : this.selectedTripType.value;

            if (this.isSelfpay == false) {
                servicetype = this.selectedServiceType.value;
                corporate_account_id = this.booking.self_pay.corporate_account.id;
                url = `admin/panel/calculate_price_corporate_account_nup`;
            }

            formData.append("servicetype", servicetype);
            formData.append("miles", miles);
            formData.append("waitingTime", waitingTime);
            formData.append("tripType", trip_type);
            formData.append("corporate_account_id", corporate_account_id);
            formData.append("additionalStop", additionalStop);
            formData.append("bookingId", this.booking.id);

            await this.$http
                .post(url, formData)
                .then((res) => {

                    this.booking.hasMinimumFee = res.data.data.hasMinimumFee;

                    if (this.booking.hasMinimumFee == true) {
                        this.booking.minimum_charge = toFixed(res.data.data.totalTrip, 2);
                    } else {
                        this.booking.minimum_charge = 0;
                    }

                    if (this.is_automatic_price === "1" && updateBookingPrice == true) {
                        this.booking.price = toFixed(res.data.data.totalTrip, 2);
                    }

                    if (this.is_automatic_price === "1") {
                        if (this.isSelfpay == false) {
                            this.booking.base_charge = res.data.data.totalBaseEachWay;
                            this.booking.additional_miles_charges = null;
                        } else {
                            this.booking.base_charge = null;
                            this.booking.additional_miles_charges = res.data.data.totalAdditionalMiles;
                        }

                        this.booking.additional_stops_charges = res.data.data.totalAdditionalStop;
                        this.booking.wait_time_charges = res.data.data.totalWaitingTime;
                        this.booking.miles_charges = res.data.data.totalMiles;
                    }

                    if (this.is_automatic_fee === "1") {
                        this.booking.each_way_fee = res.data.data.each_way_fee;
                        this.booking.mile_fee = res.data.data.mile_fee;
                        this.booking.wait_time_fee = res.data.data.wait_time_fee;
                        this.booking.additional_stop_fee = res.data.data.additional_stop_fee;
                        this.booking.cancellation_fee = res.data.data.cancellation_fee;
                        this.booking.minimum_fee = res.data.data.minimum_fee;
                        this.booking.minimum_miles_fee = res.data.data.minimum_miles_fee;
                        this.booking.additional_miles_fee = res.data.data.additional_miles_fee;
                    }

                    this.booking.price = convertToNumber(this.booking.price);

                    let tmpTotalAdditionalStop = convertToNumber(res.data.data.totalAdditionalStop);

                    if (this.is_automatic_additional_stops === "1") {
                        this.booking.total_additional_stops = toFixed(tmpTotalAdditionalStop, 2);
                    }

                    if (this.is_automatic_waiting_time === "1") {
                        this.booking.total_waiting_time = toFixed(res.data.data.totalWaitingTime, 2);
                    }

                    this.booking.tripSupported = res.data.data.tripSupported;
                    this.$swal.close();
                })
                .catch((res) => {
                    this.$swal.close();
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });

            let ddd = this.GetTotalExtraCharge(this.booking) + parseFloat(this.booking.price);
            this.totalWithExtraCharge = ddd;
        },
        async calculateDistance() {
            if (this.is_automatic_miles == 1) {

                this.disablebUpdateButton = false; this.$swal({
                    title: "Please, wait calculating distance...",
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                });

                let additionalStops = this.booking.additional_service.filter(element => JSON.parse(element.service.toLowerCase().includes('stop')));
                this.booking.total_miles = toFixed(await doCalculations(this.booking, additionalStops), 0);
                this.$swal.close();
                this.calculatePrice();
            }
        },
        async getStatusCodes() {
            await this.$http
                .get(`get_list_status`)
                .then((response) => {
                    let data = response.data;
                    this.optionsStatusCodes = [];
                    data.forEach(element => this.optionsStatusCodes.push({ 'title': element.status, 'value': element.code }));
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async sendMailPdfDetailBooking() {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`/send_mail_pdf_detailst_booking/${this.booking.id}`)
                .then((response) => {
                    this.$swal({
                        title: response.data.data,
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async loadInformation() {
            await this.loadMenus();
            await this.getInformationBooking(this.bookingId);
            let adfs = 0;
        },
        changeStatusBooking(data) {
            this.booking.status = data.value;
        },
        async saveData() {
            this.booking.service_type = this.selectedServiceType == null ? null : this.selectedServiceType.value;
            this.booking.trip_type = this.selectedTripType == null ? null : this.selectedTripType.value;
            this.booking.status = this.selectedStatusCode.value;

 

            if (this.validationForm() == false) {
                return false;
            }
            let res = false;

            if (this.oldStatusBooking != this.booking.status) {
                res = await this.$swal({
                    title: "Are you sure you want to change the status? If yes, please confirm that you have audited the ticket and that all information, including pricing, is correct?",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: true,
                }).then((result) => {
                    return result.value;
                });

                if (res == true) {
                    this.oldStatusBooking = structuredClone(this.booking.status);
                } else {
                    let resOption = this.optionsStatusCodes.find(
                        (item) => item.value === this.oldStatusBooking
                    );

                    if (resOption) {
                        this.selectedStatusCode = resOption;
                    }
                    this.booking.status = structuredClone(this.oldStatusBooking);
                    return false;
                }
            }

            if (this.booking.status == 4) {
                this.is_automatic_price = "0";
                this.booking.driver_price = 0;
                this.booking.additional_charges_driver = 0;
                this.booking.price = 0;
            }


            await this.updateFeesBooking();
            await this.calculatePrice();
            this.setValuesFees();
            //await this.saveChargesBooking(false, false);

            if((this.booking.is_automatic_price=="1" || this.booking.is_automatic_price==1)==false)
            {
                this.booking.minimum_fee=0;
            }

            this.$swal({
                title: "Please, wait saving data...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            this.$http
                .post(
                    `admin/panel/booking/${this.booking.id}/modify_prices_bookingservice`,
                    this.booking
                )
                .then((res) => {


                    let data = structuredClone(this.booking);
                    data.is_automatic_price = data.is_automatic_price == "1" ? 1 : 0;
                    this.$emit('load-data', data, false);

                    this.getInformationBooking(this.booking.id)
                    this.$swal({
                        title: "Data was updated successfully ",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });

                }
                )
                .catch((res) => {
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async saveChargesBooking(showMessage, doCalculations = true) {
            console.log('saveChargesBooking')
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let url = `admin/panel/update-charges-booking`;
            await this.$http
                .post(url, this.booking)
                .then((res) => {
                    this.updatePriceBooking();
                })
                .catch((res) => {
                    this.$swal.close();
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async updatePriceBooking(showMessage) {
            await this.saveData();
            let data = structuredClone(this.booking);
            data.is_automatic_price = data.is_automatic_price == "1" ? 1 : 0;
            this.$emit('load-data', data, false);


            if (showMessage == true) {
                this.$swal({
                    title: "Data was updated successfully ",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
            }
        },
        getChargeId(booking) {
            console.log("getChargeId")
            let res = "";

            try {
                if (booking.charge_id) {
                    res = booking.charge_id;
                } else {
                    if (booking.invoices.length > 0) {
                        res = booking.invoices[0].charge_id;
                    }
                }
            } catch (error) {

            }

            return res;
        },
        GetTotalExtraCharge(booking) {
            let res = GetTotalExtraCharge(booking);           
            return res;
        },
       

    },
    mounted() {
        this.loadInformation();
    },
}

</script>


<style lang="scss"></style>