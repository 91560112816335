import moment from "moment"


export const convertToMMDDYYYY = function (data) {
    let res = data

    try {
        if (data != null || data != undefined) {
            res = moment(data).format('MM-DD-YYYY');
        }
    } catch (err) {
        console.log(err)
    }

    return res
}

export const convertToHHMM = function (data) {
    let res = data

    try {
        if (data != null || data != undefined) {
            res = moment(data).format('hh:mm A');
        }
    } catch (err) {
        console.log(err)
    }

    return res
}


export const convertToHHMM2 = function (data) {
    let res = data

    try {
        if (data != null || data != undefined) {
            // res = moment(data).format('hh:mm A');
            res = moment(data, "HH:mm:ss").format("hh:mm A")

        }
    } catch (err) {
        console.log(err)
    }

    return res
}


export const convertToMMDDYYYYHHmm = function (data) {
    let res = data

    try {
        if (data != null || data != undefined) {
            res = moment(data).format('MM-DD-YYYY hh:mm A');
        }
    } catch (err) {
        console.log(err)
    }

    return res
}


export const addTimes = function addTimes(t0, t1) {
    return secsToTime(timeToSecs(t0) + timeToSecs(t1));
}

// Convert time in H[:mm[:ss]] format to seconds
export const timeToSecs =function timeToSecs(time) {
    let [h, m, s] = time.split(':');
    return h * 3600 + (m | 0) * 60 + (s | 0) * 1;
}

// Convert seconds to time in H:mm:ss format
export const secsToTime =function secsToTime(seconds) {
    let z = n => (n < 10 ? '0' : '') + n;
    return (seconds / 3600 | 0) + ':' +
        z((seconds % 3600) / 60 | 0) + ':' +
        z(seconds % 60);
}