<template>
    <div>

        <b-card>

            <b-row>
                <b-col md="12">
                    <h4 class="mb-0">Billing Notes </h4>
                    <br />
                </b-col>
            </b-row>

            <b-row md="12">
                <b-col md="12" v-if="listLogs.length > 0">
                    <b-table-simple hover small caption-top responsive>
                        <b-thead head-variant="dark">
                            <b-tr>
                                <b-th>User</b-th>
                                <b-th>Comment</b-th>
                                <b-th>Status booking</b-th>
                                <b-th>Date</b-th>
                                <b-th></b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(log, index) in listLogs" :key="index">
                                <template v-if="log.type_log == 'note'">
                                    <b-td>
                                        {{ log.username }}
                                    </b-td>
                                    <b-td>
                                        {{ log.comments }}
                                    </b-td>
                                    <b-td style="min-width: 300px">
                                        {{ log.status }}
                                    </b-td>
                                    <b-td style="min-width: 50px">
                                        {{ log.datetime_log }}
                                    </b-td>
                                    <b-td>
                                        <td>
                                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                                                transition="scale-transition" :offset-y="true">
                                                <template #button-content>
                                                    <feather-icon icon="MoreVerticalIcon" size="16"
                                                        class="align-middle text-body" />
                                                </template>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                                                    </b-btn>
                                                </template>
                                                <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                                                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                                        <b-list-group-item class="font-weight-bold"
                                                            @click="openModalEditNote(log)"
                                                            style="border: none; padding: 0px; color: #7367f0">
                                                            <feather-icon icon="CheckIcon" />Edit
                                                        </b-list-group-item>
                                                    </b-list-group-item>
                                                </b-list-group>
                                                <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                                                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                                        <b-list-group-item class="font-weight-bold"
                                                            style="border: none; padding: 0px; color: #7367f0"
                                                            @click="deleteNote(log.id)">
                                                            <feather-icon icon="CheckIcon" />Delete
                                                        </b-list-group-item>
                                                    </b-list-group-item>
                                                </b-list-group>
                                                <!--End Timer -->
                                            </b-dropdown>
                                        </td>
                                    </b-td>
                                </template>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>


    

        </b-card>



        <b-modal id="bv-modal-note" centered hide-footer size="lg">
            <div class="d-block text-center">
                <h3>Write a Note</h3>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <b-form-textarea v-model="messageText" rows="6" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <b-row class="mt-3">
                <b-col>
                    <b-button block @click="saveNote('billing', 'note')">Save</b-button>
                </b-col>
                <b-col>
                    <b-button block @click="$bvModal.hide('bv-modal-note')">Close</b-button>
                </b-col>
            </b-row>
        </b-modal>



        <b-modal ref="modal-edit-notes" id="modal-edit-notes" cancel-only centered size="md" title="Edit note">
            <b-form-group label="Comment">
                <b-form-textarea v-model="note.comments" rows="4" />
            </b-form-group>

            <b-row>
                <b-col cols="12 text-right">
                    <b-button class="btn btn-primary" @click="updateNote">
                        Save
                    </b-button>
                </b-col>
            </b-row>

            <template #modal-footer>
                <div class="w-100">
                </div>
            </template>
        </b-modal>


    </div>
</template>
  
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { getMessageError } from "@core/utils/utils";
import {
    getMenuOptionsTripType,
    getMenuOptionsServiceType,
} from "@core/utils/menus";

import {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BForm,
    BFormCheckbox,
    BCard,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BDropdown,
} from "bootstrap-vue";
import moment from "moment";

export default {
    components: {
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormSelect,
        BFormDatepicker,
        BFormTimepicker,
        vSelect,
        BTableSimple,
        BThead,
        BTr,
        BTd,
        BTh,
        BTbody,
        BTfoot,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormCheckbox,
        BCard,
        BFormTextarea,
        moment,
        BListGroup,
        BListGroupItem,
        BDropdown,
    },
    props: {
        bookingId: 0,
        statusBooking: "",
    },
    data() {
        return {
            listLogs: [],
            messageText: "",
            note: {}
        }
    },
    methods: {
        openModalEditNote(item) {
            this.note = item;
            this.$bvModal.show("modal-edit-notes");
        },
        getNotesBilling(bookingId) {
            this.$http
                .get(`admin/panel/booking/get_booking_notes_by_booking_id?booking_id=${bookingId}`)
                .then((response) => {
                    this.listLogs = response.data.data;
                    this.listLogs = this.listLogs.filter((item) => item.type_note == "Note for billing");
                    this.$swal.close();
                })
                .catch((error) => {
                    let message = getMessageError(error);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        saveNote(typeNote, typelog) {
            if (this.messageText === "") {
                this.isMessageText = false;
            } else {
                this.$bvModal.hide("bv-modal-note");
                this.$bvModal.hide("bv-modal-sms");

                this.$swal({
                    title: "Please, wait...",
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                });
                let formData = new FormData();
                formData.append("text", this.messageText);
                formData.append("messageSendTo", "");
                formData.append("status", this.statusBooking);
                formData.append("type_log", typelog);
                formData.append("type_note", typeNote);
                formData.append("alternative_number_driver", "");
                formData.append("alternative_number_passenger", "");
                formData.append("datetime_log", moment().format("YYYY-MM-DD hh:mm A"));

                this.$http
                    .post(
                        `/admin/panel/booking/${this.bookingId}/saveBookingNote`,
                        formData
                    )
                    .then((res) => {
                        this.getNotesBilling(this.bookingId);
                    })
                    .catch((error) => {
                        this.$swal({
                            title: getMessageError(error),
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                    });
            }
        },
        updateNote() {
            this.$bvModal.hide("modal-edit-notes");
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let formData = new FormData();
            formData.append("id", this.note.id);
            formData.append("comments", this.note.comments);
            formData.append("type_note", this.note.type_note);

            this.$http
                .post(
                    `/admin/panel/booking/edit_booking_note`,
                    formData
                ).then((res) => {
                    this.$swal({
                        title: res.data.message,
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    }).then((result) => {

                    });
                }).catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        deleteNote(id) {
            this.$swal({
                title: "Do you want delete this record?",
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                buttonsStyling: true,
            }).then((result) => {
                if (result.value === true) {
                    this.$swal({
                        title: "Please, wait...",
                        didOpen: () => {
                            this.$swal.showLoading();
                        },
                    });
                    this.$http
                        .post(`/admin/panel/delete_booking_note/${id}`)
                        .then((res) => {
                            this.listLogs = this.listLogs.filter((item) => item.id != id);
                            this.$swal({
                                title: res.data.message,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        })
                        .catch((error) => {
                            this.$swal({
                                title: getMessageError(error),
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
    },
    mounted() {
        this.getNotesBilling(this.bookingId);
    },
}

</script>
  

  