export const toFixed = function(data, numFixed) {
    let res = data

    try {
        data=Number(data);
        res = data.toFixed(numFixed)
    } catch (err) {
        console.log('err toFixed');
    }
    return res
}

export const convertToNumber = function(data) {
    let res = 0

    try {
        res=Number(data);
         if(isNaN(res))
         {
            res=0;
         }
         
    } catch (err) {
        console.log('err toFixed');
    }
    return res
}